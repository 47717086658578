import { ChevronDownIcon, ChevronUpIcon } from "@ordr-as/icons";
import { EposDisplayColorDto, SectionContainingProductDto } from "types/contracts/contracts";
import RainbowColoredBox from "./RainbowColoredBox";

type Props = {
  editable: boolean;
  eposDisplayColors: EposDisplayColorDto[];
  selectedSection: SectionContainingProductDto | undefined;
  selectedProductColor: EposDisplayColorDto | undefined;
  isOpen: boolean;
  onClick: () => void;
};

const ColorSelectorButton = (props: Props) => {
  const { editable, eposDisplayColors, selectedSection, selectedProductColor, isOpen, onClick } = props;
  const selectedSectionColor = eposDisplayColors.find((color) => color.hexCode === selectedSection?.sectionEposDisplayColor);

  const useButtonText = () => {
    if (selectedProductColor) {
      return selectedProductColor.name;
    }

    if (selectedSectionColor) {
      return `${selectedSectionColor.name} (colour from section)`;
    }

    return "Use section colour (Defined by each section)";
  };
  const buttonText = useButtonText();

  const ColorBox = () => {
    const classNames = "h-4 w-4 rounded";

    if (!selectedSection && !selectedProductColor) {
      return <RainbowColoredBox classNames={classNames} />;
    }

    if (selectedProductColor) {
      return <div className={`${classNames}`} style={{ backgroundColor: selectedProductColor.hexCode }} />;
    }

    if (selectedSectionColor) {
      return <div className={`${classNames}`} style={{ backgroundColor: selectedSectionColor.hexCode }} />;
    }

    return <RainbowColoredBox classNames={classNames} />;
  };

  return (
    <button id="productEposDisplayColor" type="button" className="umami-medium-button umami-secondary-button" disabled={!editable} onClick={onClick}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ColorBox />
          <span className="pl-2 text-grey-dark">{buttonText}</span>
        </div>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
    </button>
  );
};

export default ColorSelectorButton;
